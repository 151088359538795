@import 'custom_bootstrap.scss';

@font-face {
  font-family: ESA;
  src: url('./fonts/NotesEsaReg.otf') format('opentype');
}
@font-face {
  font-family: ESA;
  src: url('./fonts/NotesEsaBol.otf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: ESA;
  font-style: italic;
  src: url('./fonts/NotesEsaRegIta.otf') format('opentype');
}
@font-face {
  font-family: ESA;
  font-style: italic;
  src: url('./fonts/NotesEsaBolIta.otf') format('opentype');
  font-weight: bold;
}

.clear-button {
  border: unset;
  background: unset;
}

.react-datepicker__tab-loop {
  display: contents;
  &__end,
  &__start {
    display: none;
  }
}

div.react-select {
  &__control {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25;
    min-height: 30px !important;
    min-width: 150px;
  }
  &__indicator {
    padding: 6.5px !important;
  }
}

html {
  font-family: 'ESA';
  height: 100%;
}

.standard-container {
  @extend .shadow-sm;
  padding: 21px 35px;
  margin-bottom: 20px;
  background-color: $white;
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: auto;
  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-color: $background;
    padding: 0 16px;
    width: 100%;
    &-centralised {
      @extend .standard-container-wrapper;
     align-items: center;
     justify-content: center;
    }
  }
  &-standalone {
    @extend .standard-container;
    margin-top: 20px;
    height: calc(100% - 40px);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'ESA';
  height: 100%;
  color: $black;
}

a {
  text-decoration: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $police-blue #fff;
}

*::-webkit-scrollbar {
  width: 0.75em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $police-blue;
  outline: 1px solid $black;
}

.bi {
  font-size: 18px;
}

.bigIcon {
  height: 18px;
  width: 18px;
}
