@import 'colors.scss';

.input {
  min-width: 175px;
}

.customDateWrapper {
  display: flex;
  flex-direction: column;
}

.inputError {
  color: $red;
}

.actions {
 float: right;
 margin: 5px 15px 10px 15px
}
