@import 'colors.scss';

.expandedEvent {
  padding: 0 55px;
  margin-bottom: 46px;

  &Title {
    color: $weldon-blue;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 18px;
  }

  &Text {
    color: $black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 8px;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
