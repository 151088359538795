@import 'colors.scss';

.eventsTable {
  &Name {
    min-width: 150px;
  }

  &Date {
    min-width: 150px;
  }

  &RangeDate {
    min-width: 150px;
    display: flex;
    flex-direction: row;
    gap: 5px
  }
}


.eventDesc {
  height: 1.5em;
  overflow-y: hidden;
  white-space: pre-wrap;
}