@import 'colors.scss';

.status-indicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &.red {
    background: $red;
  }
  &.green {
    background: $green;
  }
  &.yellow {
    background: $yellow;
  }
  &.gray {
    background: $gray;
  }
}
