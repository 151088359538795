.AppContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}
.AppContainerContent {
  flex: 1;
  width: 100%;
  min-width: 0;
}
