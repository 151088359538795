@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 21px 35px;
  margin-bottom: 20px;
  background-color: $white;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.levelMargin {
  margin-left: 40px;
}

.tabs {
  margin-bottom: 15px;
}

.reportMenuIconButton {
  color: $weldon-blue;
  font-size: 12px;
  line-height: 1;
}

.reportMenu {
  display: flex;
  justify-content: flex-end;
}
