@import 'colors.scss';
$background-opacity: 0.4;

.newLockedPeriodCreation {
  display: flex;
  flex-direction: row;

  &DateSelector {
    &Wrapper {
      max-width: 250px;
      margin-right: 12px;
      width: 100%;
    }

    color: $black;
    font-size: 16px;
    background: rgba($gainsboro, $background-opacity);
    padding: 19px 35px;
    box-shadow: unset;
    min-height: 60px;
    border-color: transparent;
    resize: none;
    &::placeholder {
      color: $black;
    }
  }

  &UnitSelector {
    background: rgba($gainsboro, $background-opacity);
    box-shadow: unset;
    max-width: 250px;
    margin-right: 12px;
    margin-bottom: 1rem;
    border-radius: 10px;
    width: 100%;
    & > div:first-of-type {
      height: 100%;
    }
    & > div {
      background: rgba(238, 240, 237);
      border: none;
    }
  }

  &Input {
    width: 100%;
  }
}
