@import 'colors.scss';

.table {
  color: $black;
  width: 100%;

  thead {
    border-bottom: 1px solid black;
    color: $police-blue;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
  }
  th,
  td {
    font-weight: normal;
    padding: 8px;
    white-space: nowrap;
  }

  td {
    border-bottom: 1px solid #dbe5e8;
    text-align: start;

    /* This is a workaround for react-table recommended by its developers */
    /* Makes cells grow equally */
    width: 1%;

    position: relative;

    /* width 1% brakes columns on firefox */
    @-moz-document url-prefix() {
      width: auto;
    }
  }

  button {
    background-color: inherit;
    border-bottom: none;
  }

  &RowExpanded,
  &CellExpanded {
    background-color: #fbfafd;
  }

  &CellExpanded {
    border-bottom: none !important;
  }

  &Collapse {
    /* This is a workaround for react-table recommended by its developers */
    /* Makes column smallest size fitting content */
    width: 0.0000000001% !important;
  }

  &Expander {
    display: inline-block;
    padding: 0 10px;
    transition: all 0.2s;

    &Active {
      transform: rotate(90deg);
    }
  }

  &HeaderCentered {
    text-align: center !important;
  }

  &EditButtons {
    display: flex;
    padding: 0 10px;

    button {
      border: none;
      width: 25px;
      height: 25px;

      i {
        color: $weldon-blue;
      }
    }
  }
}

.sortIcon {
  margin-left: 4px;
  font-size: 13px;
}
