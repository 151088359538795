.eventsPanel {
  height: 100%;
  margin-bottom: 20px;
  overflow: auto;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
