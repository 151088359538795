.counter {
  margin-top: 5px;
  padding: 0 15px;
  text-align: right;
  font-size: 12px;
}

.container {
  display: flex;
}
