.checkboxGroup {
  &Label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    height: 30px;
  }
  &List {
    display: grid;
    row-gap: 4px;
    grid-auto-rows: min-content;
    margin-left: 5px;
  }
  &Badge {
    margin-left: 4px;
  }
}
