@import 'colors.scss';

.sidebarSection {
  margin-top: 20px;

  &Title {
    color: $primary;
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 6px;
    margin-left: 32px;
  }
}
