.remarksPanel {
  height: 100%;
  margin-bottom: 20px;
  overflow: auto;
}

.go {
  &:hover {
    background-color: #91f0e6 !important;
    border-color: #8befe5;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.remarkDesc {
  height: 1.5em;
  overflow-y: hidden;
  white-space: pre-wrap;
}
