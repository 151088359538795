@import 'colors.scss';

.sprintSelector {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  &Icon {
    width: 14px;
    height: 15px;
  }

  &Label {
    color: $black;
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
  }

  &Value {
    color: $black;
    font-size: 16px;
    font-weight: bold;
    padding-right: 5px;
  }

  &Status {
   text-transform: capitalize;
   background: $cyan;
   font-size: 12px;
   padding: 2px 10px;
   margin-right: 5px;
   border-radius: 10px;
  }

}
