@import 'colors.scss';

.mainTable {
  
}

.mainTable td:first-child  {
  border-bottom-width: 0px !important;
}

.mainTable > thead > tr > th {
  &:first-child {
    width: 65px;
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.weekCell {
  padding-left: 0 !important;
}

.week {
  top: 100%;
  position: absolute;
  transform: translateY(-50%);
  background: #fff;
  padding: 0 1rem 0 0;
  font-weight: 800;
  font-size: 12px;
  z-index: 1;
}

.tableCellWeek {
  border-bottom-color: $black !important;
  border-bottom-width: 2px !important;
  &:first-child {
    border-bottom-width: 0px !important;
  }
  &Spacer {
    border-bottom-color: transparent !important;
  }
}

.fitCell {
  white-space: pre-wrap !important;
  word-break: break-all;
}
