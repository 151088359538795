@import 'colors.scss';

.ideasPage {
  background-color: $background;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
  width: 100%;

  &Menu {
    align-items: center;
    background-color: $background;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 10px;
    width: 100%;
    z-index: 1;

    &AddButton {
      width: 120px;
    }
  }

  td button {
    background-color: inherit;
    border: none;
    color: $weldon-blue;
    font-size: 13px;
    line-height: 13px;
  }

  &Table {
    &Buttons {
      display: flex;
      padding: 0 10px;
    }
  }
}
