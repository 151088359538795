.adminPanel {
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: auto;
  &Loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
