.toggleButton {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.choiceLabel {
  padding-left: 0.25rem;
  font-size: 14px;
}

.formCheck {
  padding-right: 0.75rem;
}

.selectLabel {
 max-width: 340px;
}
