@import 'colors.scss';

.searchPage {
  &Form {
    background-color: rgba(51, 94, 111, 0.2);
    margin: -21px -35px 21px -35px;
    padding: 28px;

    &Title {
      color: $black;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
      margin-left: 8px;
    }

    &Inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      &Row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        gap: 6px;

        button {
          padding: 0 32px;
        }

        > div {
          min-width: 180px;
        }
      }
    }

    &SearchInput {
      min-width: 365px;
    }

    &DateInput {
      width: 180px;

      button {
        padding: 0 6px 0 0;
      }
    }

    &Sections {
      display: flex;
      flex-direction: row;

      button {
        display: flex;
        background-color: inherit;
        border: none;
        padding: 0;

        i {
          color: $weldon-blue;
          margin-top: 18px;
        }
      }
    }

    &Section {
      margin: 21px;
      margin-bottom: 0;
      min-width: 120px;

      &Header {
        display: flex;
        flex-direction: row;
      }

      &Title {
        color: $black;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: uppercase;
      }

      &Badge {
        align-items: center;
        background-color: $turquoise;
        border-radius: 50%;
        display: flex;
        font-size: 12px;
        height: 20px;
        justify-content: center;
        letter-spacing: 0;
        line-height: 14px;
        margin-left: 8px;
        text-align: right;
        width: 20px;
      }

      &Options {
        margin-left: 2px;
        margin-top: 12px;

        label {
          color: $black;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          margin-left: 5px;
          text-align: center;
        }
      }

      &Option {
        &Checked {
          label {
            font-weight: bold;
          }
        }
      }
    }
  }
}
