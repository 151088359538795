@import 'colors.scss';

$component-active-color: $police-blue;
$component-active-bg: $turquoise;

$input-color: $police-blue;
$input-placeholder-color: $weldon-blue;
$input-border-radius: 10px;
$input-font-size: 12px;
$input-line-height: 1.25;
$input-padding-y: 9px;
$input-padding-x: 10px;

$form-check-input-border: 1px solid $weldon-blue;
$form-check-input-border-radius: 2px;

$btn-border-radius-sm: 16px;
$btn-border-radius: 16px;
$btn-border-radius-lg: 16px;
$btn-font-size-sm: 14px;
$btn-font-size: 14px;
$btn-font-size-lg: 14px;

$badge-font-size: 12px;
$badge-border-radius: 15px;
$badge-padding-y: 8px;
$badge-padding-x: 15px;
$badge-color: $black;
$badge-font-weight: normal;

$breadcrumb-divider: quote('>');

$min-contrast-ratio: 4.2;

$box-shadow-sm: 0px 1px 5px 0px rgba(0, 0, 0, 0.23);

$font-size-base: 0.75;

$nav-tabs-link-active-color: $brandeis-blue;
$nav-link-color: $black;
$nav-link-hover-color: mix(white, $black, 30%);
$nav-tabs-link-active-border-color: transparent transparent $brandeis-blue;
$nav-tabs-link-hover-border-color: transparent transparent $white;

@import '~bootstrap/scss/bootstrap';

.btn {
  &-primary {
    color: $black;
  }

  &-icon {
    padding: 0.25rem;
    font-size: 0.875rem;
    border-radius: 100%;
    line-height: 0.9;
    &-small {
      @extend .btn-icon;
      padding: 0;
    }
    &-dark {
      @extend .btn-icon;
      color: $black;
      border-color: $dark;
    }
  }

  @each $color, $value in $theme-colors {
    &-outline-#{$color} {
      &:hover {
        background-color: rgba($value, 0.85) !important;
      }
    }
  }
}

.badge {
  &-xs {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
  }
  &.bg-primary {
    background: $cyan;
  }
  &.bg-secondary {
    background: $orange;
  }
  &.bg-action {
    background: $orange;
  }

  &.bg-decision {
    background: $bright-lavender;
  }
  &.bg-gray {
    background: $platinum;
  }
  &.bg-needToKnow {
    background: $cyan;
  }
  &.bg-label {
    background: $platinum;
  }
  &.rounded-pill {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 12px;
  }
}

.form-check-input {
  flex-shrink: 0;
}

// .table {
//   border-color: $platinum;
//   thead {
//     vertical-align: middle;
//     height: 40px;
//     th {
//       color: $police-blue;
//       font-weight: normal;
//       font-size: 12px;
//       text-transform: uppercase;
//       line-height: 14px;
//     }
//   }
//   tbody {
//     border-top: unset !important;
//     td {
//       vertical-align: middle;
//     }
//   }
// }

.breadcrumb-item {
  &:first-of-type {
    font-weight: 900;
  }
  &:last-of-type {
    color: $police-blue;
    font-weight: 900;
  }
  a {
    color: $black;
    font-size: 14px;
  }
}

.form-check-input {
  &[type='radio'] {
    & + label {
      color: $police-blue;
    }
    &:checked + label {
      color: $black;
      font-weight: bold;
    }
  }
}

.nav-tabs {
  border-top: $nav-tabs-border-width solid $nav-tabs-border-color;
  .nav-link {
    background-color: inherit !important;
  }
}

// It fix bootstrap bug
.btn-check {
  left: 0;
  top: 0;
}

.form-check-input[type='radio'] {
  background-color: transparent;
  border-color: $weldon-blue;
}

// Keyword !important is added here, because in some cases valid styles overwrite invalid. It is bug in react-bootstrap library.
input,
textarea {
  .was-validated &:invalid,
  &.is-invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") !important;
    border: 1px solid #f51000 !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(245 16 0 / 25%) !important;
    }
  }
}
