$cyan: #7eede2;
$weldon-blue: #799caa;
$police-blue: #335e6f;
$brandeis-blue: #0076ff;
$dark-blue: #022838;
$turquoise: #4df6e5;
$green: #3af167;
$yellow: #ffcd39;
$orange: #ffa881;
$red: #f51000;
$bright-lavender: #a18bfc;
$white-coffee: #dbe5e8;
$platinum: #e4e4e8;
$french-sky-blue: #7eb9fd;
$gainsboro: #d9ded7;
$gray: #ccccca;
$gray-400: #ccccca;
$gray-600: #ccccca;
$black: #003348;
$white: #ffffff;
$light-slate-gray: #6b8c99;
$medium-aquamarine: #63d3b2;

$background: #f2f5f1;
$transparentGrey: #D3D3D3AA;
$transparent: #00000000;

$primary: $cyan;
$secondary: $black;
