@import 'colors.scss';

.sidebarButton {
  align-items: center;
  background-color: inherit;
  border: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 42px;
  line-height: 17px;
  padding-left: 32px;
  text-align: left;
  width: 100%;
  background: $black;
  border-left: 3px solid transparent;
  &Overlay {
    display: flex;
    justify-content: center;
    width: 200px;
    padding-left: 0;
    padding-right: 3px; // Because we want text to be centered and on the left side we have 3px border
  }
  &Icon {
    margin-right: 13px;
    width: 17px;
    height: 17px;
    color: $cyan;
    text-align: center;
    &Disabled {
      color: $transparentGrey;
    }
  }

  &Label {
    display: flex;
    flex-direction: column;
  }

  &SubLabel {
    line-height: 12px;
    font-size: 10px;
  }

  &Active,
  &:hover {
    background-color: $dark-blue;
    border-left-color: $cyan;
    color: $cyan;
  }

  &Disabled, &Disabled:hover  {
    background-color: inherit;
    border-left-color: $transparent;
    color: $transparentGrey;
    cursor: auto;
  }

}
