@import 'colors.scss';

$font-size: 12px;

.menuBar {
  align-items: center;
  background-color: $background;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  &Buttons {
    display: flex;
    gap: 4px;
    margin-left: auto;
    align-items: center;
  }

  &Search {
    height: 32px;
    max-width: 288px;
    min-width: 200px;
  }

  &ShowOnly {
    align-items: center;
    color: $black;
    display: flex;
    flex-direction: row;
    font-size: $font-size;
    line-height: 14px;
    text-align: left;
    margin-right: 10px;
  }

  &SearchDate {
    height: 32px;

    &Wrapper {
      width: 175px;
      height: 32px;
    }
  }

  &ShowList,
  &VisibilityFilter {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 4px;
    &Wrapper {
      display: flex;
      align-items: center;
      font-size: $font-size;
      margin-right: 10px;
    }
  }

  &Radio {
    margin-right: 0;
  }

  &Checkbox {
    align-items: center;
    display: flex;
    margin-left: 10px;

    label {
      padding-left: 5px;
    }

    input {
      height: 17px;
      margin-top: 0;
      width: 17px;
    }
  }
}
