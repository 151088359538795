@import 'colors.scss';

.archivedIdeasPage {
    background-color: $background;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px;
    width: 100%;

    td button {
        background-color: inherit;
        border: none;
        color: $weldon-blue
    }

    &Menu {
        align-items: center;
        background-color: $background;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 10px;
        width: 100%;
        z-index: 1;
    }
}