.searchTable {
    &Status {
    }

    &TagsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
    }

    &ReportCell{
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }  
    &LoadingWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
    }
}