@import 'colors.scss';

.loadingSelectContainer {
  position: relative
}

.loadingSelectWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: $transparentGrey;
  justify-content: center;
}
