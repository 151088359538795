@import 'colors.scss';

.sidebarSelect {
  background: inherit;
  border-color: $primary;
  color: $white;
  &Label {
    color: $primary;
    font-size: 9px;
    line-height: 10px;
    margin-bottom: 6px;
  }
  &Wrapper {
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 10px;
  }
}
