.form {
  margin: 10px 0;
  padding: 25px 55px;
  height: -webkit-fill-available;
  border: 0;

  &Grid {
    display: grid;
    gap: 17px 69px;
  }

  &ColumnGrid {
    grid-auto-flow: column;
  }

  &TopBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  &Title {
    color: #003348;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: uppercase;
  }

  &Label {
    color: #003348;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    &Required {
      color: #f00;
    }
  }

  &MandatorySection {
    margin-top: 25px;
  }

  &Buttons {
    margin-top: 25px;

    button {
      width: 150px;
      margin-right: 8px;
    }
  }
}
