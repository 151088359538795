@import 'colors.scss';

.redirectToPage {
  background-color: $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: #fff;
  gap: 20px;
}
