@import 'colors.scss';

.statisticsPage {
  &Card {
    height: 100%;
    margin-bottom: 20px;
  }
  &Loading {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
