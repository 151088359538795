.archivedProjectsPage {
  margin-top: 20px;
  height: calc(100% - 20px - 20px);
  display: flex;
}

.item {
  display: flex;
  align-items: center;
}

.unarchive {
  margin-left: auto;
}

.listGroup {
  flex-grow: 1;
}

.noneArchivedInfo {
  margin: auto;
}
