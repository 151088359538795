@import 'colors.scss';

.eventsTable {
  &Name {
    min-width: 150px;
  }

  &Date {
    min-width: 150px;
  }
}
