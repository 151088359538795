@import 'colors.scss';

.ideasSearch {
  align-items: center;
  background-color: $background;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  z-index: 2;

  &Form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &AddButton {
    width: 120px;
  }

  &Input {
    height: 32px;
    max-width: 288px;
  }

  &Date {
    height: 32px;

    &Wrapper {
      width: 175px;
      height: 32px;
    }
  }

  &UserSearch {
    max-width: 180px;
    height: 32px;
  }
}
