@import 'colors.scss';

.commentsWrapper {
  padding-top: 22px;
  padding-bottom: 22px;
}

.project {
  &Status {
    margin-right: 4px;
  }
  &HeaderButtons {
    margin-left: auto;
  }
  &IconButton {
    color: $weldon-blue;
    font-size: 12px;
    line-height: 1;
  }
  &CostCode {
    color: $medium-aquamarine;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    margin-left: 4px;
  }
  &Tabs {
    & > li > button:hover {
      border-bottom-color: $weldon-blue !important;
      color: $weldon-blue;
    }
  }
}
