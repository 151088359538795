@import 'colors.scss';

.searchBar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  &Row {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.searchBtn {
  width: 130px;
}
.searchFilters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  row-gap: 12px;
}

.searchInput {
  width: 300px;
}

.dateInput {
  width: 175px;
}

.expandFiltersBtn {
  color: $weldon-blue;
  background: none;
  border: none;
  align-self: flex-start;
  transition: 0.3s all;
  &.expanded {
    transform: rotate(180deg);
  }
}
