@import 'colors.scss';
@import '~bootstrap/scss/functions.scss';

.sidebar {
  background-color: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;

  &Header {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    padding-top: 23px;
    padding-bottom: 10px;
    padding-left: 33px;
    text-align: left;
  }

  &Buttons {
    overflow-y: auto;
  }

  &Main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 10px solid #003348;
  }

  &Footer {
    border-top: 1px solid $primary;
    color: #f8f8f8;
    font-size: 10px;
    line-height: 11px;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 33px;
    padding-top: 10px;

    a {
      text-decoration: none;
    }

    &Logo {
      color: $white;
    }

    &Version {
      font-weight: bold;
      margin-top: 11px;
    }

    &Env {
      margin-top: 10px;
      &Copy {
        color: $primary;
        cursor: pointer;
        &:hover {
          color: shift-color($primary, 20%);
        }
      }
      pre {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
