@import 'colors.scss';
$background-opacity: 0.4;

.newEventCreation {
  display: flex;
  flex-direction: row;

  &DateSelector {
    &Wrapper {
      max-width: 250px;
      margin-right: 12px;
      width: 100%;
    }

    color: $black;
    font-size: 16px;
    background: rgba($gainsboro, $background-opacity);
    padding: 19px 35px;
    box-shadow: unset;
    min-height: 60px;
    border-color: transparent;
    resize: none;
    &::placeholder {
      color: $black;
    }
  }

  &Input {
    width: 100%;
  }
}
