@import 'colors.scss';

$background-opacity: 0.4;

.inputWrapper {
  display: flex;
}

.counterWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.sendImg {
  margin-left: 16px;
}

.inputError {
  color: $red;
}

.button {
  height: 60px;
  box-shadow: unset !important;
  font-size: 14px;
  color: $police-blue;
  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    background: rgba($gainsboro, $background-opacity);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 90px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: flex-end;
    &Gap {
      margin-bottom: 23px;
    }
    &::before {
      content: '';
      height: 70%;
      width: 1px;
      background: $gainsboro;
      left: 0;
      display: block;
      position: absolute;
      top: 15%;
    }
  }
}

.wideInput {
  color: $black;
  font-size: 16px;
  background: rgba($gainsboro, $background-opacity);
  padding: 19px 35px;
  box-shadow: unset;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 60px;
  border-color: transparent;
  resize: none;
  &::placeholder {
    color: $black;
  }
}
