@import 'colors.scss';

.activityHistoryPage {
  padding: 16px;
  width: 100%;
  height: 100%;
  &Inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  &Loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.breadCrumb {
  &Area,
  &SubArea {
    text-transform: uppercase;
  }
  &Title{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.tableScrollable {
  overflow-y: auto;
  margin: 10px 25px;
  table {
    margin-bottom: 0.75rem;
    thead {
      box-shadow: inset 0 -1px 0 $white-coffee;
      border-bottom: unset;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 1;
    }
  }
}

.header {
  background-color: rgba($police-blue, 0.2);
  padding: 26px 36px;
  z-index: 2;
}
