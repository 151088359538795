@import 'colors.scss';

.previousComment {
  margin-top: 10px !important;
  border-top: 1px solid $platinum;
}

.comment {
  display: flex;
  padding: 30px;
  background-color: #fbfafd;
  border-left: 1px solid $platinum;
  border-right: 1px solid $platinum;
  border-bottom: 1px solid $platinum;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid $platinum;
  }
  .main {
    flex-grow: 1;
  }
  .aside {
    flex-grow: 0;
  }
}

.commentEdit {
  width: 100%;
}


.cancelBtn {
  position: absolute;
  left: -50px;
  margin-top: 3px;
}

.author,
.date,
.dateModified {
  font-size: 12px;
  line-height: 1;
}

.author {
  color: $police-blue;
}

.date {
  color: $french-sky-blue;
}

.dateModified {
  color: $police-blue;
}

.badgeNew {
  margin-top: -4px;
}

.content, .previousContent {
  font-size: 16px;
  line-height: 19px;
  &Wrapper {
    position: relative;
    white-space: pre-wrap;
  }
}

.previousContent {
  color: $gray;
}

.previousStatus {
  font-size: 12px;
  line-height: 1;
  color: $police-blue;
  display: flex;
  gap: 10px;

  &Indicator {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
}
