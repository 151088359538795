@import 'colors.scss';

.levelHeading {
  font-size: 14px;
  font-weight: 800;
  border-bottom: 1px solid $secondary;
  padding: 13px;
  padding-right: 0px;
  display: flex;
}

.badge {
  margin-left: 4px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 40%;
}