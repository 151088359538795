.ideasTable {
    &Card {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
        margin-bottom: 10px;
        height: 100%;
        overflow: auto;

        .tableScrollable {
            max-height: 100%;
            table {
                margin-right: 1rem;
                width: calc(100% - 1rem);
                thead {
                    box-shadow: inset 0 -1px 0 #000000;
                    border-bottom: unset;
                    position: sticky;
                    top: 0;
                    background: #fff;
                    z-index: 1;
                }
            }
    }
  }
}